
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: white;
    overflow-x: hidden;
  
}

.centered-container {
  max-width: 1520px;
  margin: 0 auto;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* height: 100vh; */
}
.border-radius  {
  border-radius: 7px;
}
.my-custom-text{
  font-size: 4.75rem !important;
}
.max-height-350{
  height: 350px; display: flex; flex-direction: column;
}

.max-height-425{
  height: 422px; display: flex; flex-direction: column;
}
.z-index-0{
  z-index: 0 !important;
}

.z-index-9999{
  z-index: 9999!important;
}


@media screen and (max-width: 767px) {
.my-custom-text {
  font-size: 3.75rem !important;
}
}



@media(max-width: 767px){
  .flex.flex-row.w-full.mt-2.mb-1.items-center.justify-center {
      flex-wrap: wrap;
  }
  
  img.w-full.h-full.object-contain.mt-0.pt-0.Te {
      min-height: 74vw;
      object-fit: cover;
  }
  h2.text-2xl.md\:text-5xl.mt-10.font-semibold.text-\[\#D7AB0F\].pl-10 {
      margin: 0;
      padding: 0;
  }
  .my-custom-text {
      font-size: 1.75rem!important;
  }
  
  .flex-1.text-left.ml-20 {
      margin-left: 0;
  }
  
  p.text-lg.text-black.mt-2.pl-10 {
      padding: 0;
  }
  .grid-cols-2 {
      grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .text-6xl {
      font-size: 2.75rem;
      line-height: 1;
  }
  .max-height-425 {
    height: auto;
      display: flex;
      flex-direction: column;
  }
  }